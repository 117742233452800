import Link from 'next/link'

export default function Custom404() {
  return (
    <div>
      <div className="Custom404">
        <div className="container">
          <h1>404</h1>
          <h2>Sorry! page did not found </h2>
          <p>The page you are looking for doesn't exist or has been moved.</p>

          <div className="button-center-alignment">
            <Link href="/">
              <button>Back To Homepage</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
